@import "../../Styles/variables.scss";
@import "../../Styles/mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri+Quran&family=Reem+Kufi+Fun:wght@600&display=swap');

:root{ scrollbar-width: none }

::-webkit-scrollbar {
    display: none;
}

body {
    background: linear-gradient(90deg, darken($color-four, 30%) 0%, darken($color-five, 40%) 100%);
    font-family: Cinzel;
    color: $color-two !important;
}

.margin-top{
    margin-top: 10rem;
}

.message-background{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: darken($color-four, 20%);

    .message-text{
        margin-top: auto;
        margin-bottom: auto;
        font-size: smaller;

    }
}

.carousel-inner {
    text-align: center;
    vertical-align: middle;
}

.carousel-caption {
    background-color: scale-color($color: adjust-color($color-five, $alpha: -0.05), $lightness: -75%);
    border-radius: 10px;
    box-shadow: 10px 10px 15px black;
    position: absolute;
    top: 25px;
    padding: 0.5rem;
    font-size: 5vw;
    margin: auto;
    height: fit-content;
    text-shadow: 0px 8px 7px rgba(68, 116, 47, 1);
}

.about-us-text {
    text-align: center;
    padding: 1rem;
    font-size: larger;
    width: 100%;
    background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
    border-radius: 10px;
    box-shadow: 10px 10px 15px black;

    h3 {
        font-weight: bold;
    }

}

.carousel-item {

    display: flexbox;
    flex-direction: column;

    img {
        max-width: 100vw;
        height:auto;
    }
}

.bg-body-tertiary {
    background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
    font-family: Cinzel;
    width: 100%;
    
    .nav-link {
        color: $color-two !important;
        font-size: large;
        text-align: center;
    }

    img {
        width: 7.5vw;
        height: auto;
    }
}

.navbar-brand {
    background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-two !important;
    font-size: smaller !important;
    border-radius: 10px;
    box-shadow: 10px 7px 15px black;
    text-shadow: 0px 8px 7px black;
    padding: 1rem;
    font-size: calc(16px + 1vw) !important;
}

.container-fluid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    border-radius: 15px;
    background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
}

.greetings {
    text-align: center;
    font-family: 'Amiri Quran', serif;
    color: $color-two;

    p {
        animation-name: greeting-fade-in;
        animation-duration: 2.5s;
        animation-fill-mode: forwards;
        margin-bottom: 150px;
    }
}

.modal {
    border-radius: 15px;
}

.modal-body {
    background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.modal-header {
    background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
}

.homepage-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
}

.media-feed {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;

    h2 {
        background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
        text-align: center;
        width: 90vw;
        max-width: 30rem;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 10px 10px 15px black;
        text-shadow: 0px 8px 7px black;
    }
}

.newsletter-container{

    overflow-y: hidden;
    overflow-x: hidden;

    width: 100%;
    text-align: center;

    iframe {
        width: 100vw;
        height: 200vh;

        overflow-y: hidden;
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1000px) {
    .footer {
        background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    
        .nurturing-iman-logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 1rem;
            font-size: calc(16px + 1vw) !important;
            max-width: 35%;
    
            .logo-container {
                background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 25%) 100%);
                box-shadow: 10px 10px 15px black;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                
                img {
                    width: 25%;
                    object-fit: contain;
                }
    
                .title-text{
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    
        .socials{
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            margin: 1rem;
            font-size: larger;
            font-weight: bolder;
            text-shadow: 0px 8px 7px black;
    
            .socials-links{
                background-color: $color-four;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                box-shadow: 10px 10px 15px black;
    
                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: fit-content;
                    padding: 1rem;
                    color: $color-three;
    
                    text-decoration: none;
    
                    img {
                        width: 3rem;
                        height: 3rem;
                    }
                    
                    p {
                        margin-top: 0.5rem;
                    }
                }
    
                a:hover{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: fit-content;
                    padding: 1rem;
    
                    text-decoration: none;
    
                    img {
                        width: 3rem;
                        height: 3rem;
    
                        filter: brightness(50%);
                    }
                    
                    p {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 999px) {
    .footer {
        background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 30%) 100%);
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        flex-wrap: wrap;
    
        .nurturing-iman-logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 1rem;
            font-size: calc(16px + 1vw) !important;
    
            .logo-container {
                background: linear-gradient(90deg, darken($color-four, 20%) 0%, darken($color-five, 25%) 100%);
                box-shadow: 10px 10px 15px black;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                
                img {
                    width: 25%;
                    height: auto;
                }
    
                .title-text{
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    
        .socials{
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            margin: 1rem;
            font-size: larger;
            font-weight: bolder;
            text-shadow: 0px 8px 7px black;
    
            .socials-links{
                background-color: $color-four;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                box-shadow: 10px 10px 15px black;
    
                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: fit-content;
                    padding: 1rem;
                    color: $color-three;
    
                    text-decoration: none;
    
                    img {
                        width: 3rem;
                        height: 3rem;
                    }
                    
                    p {
                        margin-top: 0.5rem;
                    }
                }
    
                a:hover{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: fit-content;
                    padding: 1rem;
    
                    text-decoration: none;
    
                    img {
                        width: 3rem;
                        height: 3rem;
    
                        filter: brightness(50%);
                    }
                    
                    p {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }

}

@keyframes greeting-fade-in {
    from {
        opacity: 0;
        font-size: 10vw;
    }
    to {
        opacity: 1;
        font-size: 11vw;
    }
  }